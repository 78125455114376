/**
 * @name: 表单校验规则集合
 * @author: itmobai
 * @date: 2023-05-31 14:13
 * @description：表单校验规则集合
 * @update: 2023-05-31 14:13
 */
/**
 * 判断正整数
 * @param rule
 * @param value
 * @param callback
 */
export const checkPositiveInteger = (rule: any, value: any, callback: any) => {
  if (value === null || value === "" || value === undefined) {
    return callback()
  }
  if (value == 0) {
    return callback()
  }
  if (!/^[0-9]*[1-9][0-9]*$/.test(value)) {
    return callback(new Error('请输入正整数'))
  }
  callback()
}

/**
 * 判断正整数-0~999999 必填
 * @param rule
 * @param value
 * @param callback
 */
export const checkPositiveIntMandatory= (rule: any, value: any, callback: any) => {
  if (value === null || value === "" || value === undefined) {
    return callback(new Error('0~999999!'))
  }
  if (parseFloat(value) < 0 || value > 999999) {
    return callback(new Error('0~999999!'))
  }
    if (!/^[0-9]\d{0,6}$/.test(value)) {
    return callback(new Error('0~999999!'))
  }
  callback()
}
/**
 * 判断正整数-1~999999 必填
 * @param rule
 * @param value
 * @param callback
 */
export const checkPositiveIntegerMandatory = (rule: any, value: any, callback: any) => {
  if (value === null || value === "" || value === undefined) {
    return callback(new Error('1~999999!'))
  }
  if (parseFloat(value) <= 0 || value > 999999) {
    return callback(new Error('1~999999!'))
  }
  // if (!/^[0-9]*[1-9][0-9]*$/.test(value)) {
  if (!/^[1-9]\d{0,6}$/.test(value)) {
    return callback(new Error('1~999999!'))
  }
  callback()
}
/**
 * 判断正整数-1~999999 非必填
 * @param rule
 * @param value
 * @param callback
 */
export const checkPositiveIntegerNotMandatory = (rule: any, value: any, callback: any) => {
  if (value === null || value === "" || value === undefined) {
    callback()
  }
  if (parseFloat(value) <= 0 || value > 999999) {
    return callback(new Error('1~999999!'))
  }
  // if (!/^[0-9]*[1-9][0-9]*$/.test(value)) {
  if (!/^[1-9]\d{0,6}$/.test(value)) {
    return callback(new Error('1~999999!'))
  }
  callback()
}
/**
 * 判断正数-0.01~999999.99 非必填
 * @param rule
 * @param value
 * @param callback
 */
export const checkPositiveNotMandatory = (rule: any, value: any, callback: any) => {
  if (value === null || value === "" || value === undefined) {
    callback()
  }
  if (parseFloat(value) <= 0 || value > 999999.99) {
    return callback(new Error('0.01~999999.99!'))
  }
  if (parseFloat(value)>0&&!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value)) {
    return callback(new Error('0.01~999999.99!'))
  }
  callback()
}

/**
 * 判断正数-0.01~999999.99 必填
 * @param rule
 * @param value
 * @param callback
 */
export const checkPositiveMandatory =(rule: any, value: any, callback: any) => {
  if (value === null || value === "" || value === undefined) {
    return callback(new Error('0.01~999999.99!'))
  }
  if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value)) {
    return callback(new Error('0.01~999999.99!'))
  } else if (parseFloat(value) <= 0 || value > 999999.99) {
    return callback(new Error('0.01~999999.99!'))
  }
  callback()
}
/**
 * 判断金额
 * @param rule
 * @param value
 * @param callback
 */
export const checkPrice = (rule: any, value: any, callback: any) => {
  if (value === null || value === "" || value === undefined) {
    return callback()
  }
  if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value)) {
    return callback(new Error('请输入有效金额'))
  }
  callback()
}

/**
 * 判断整数
 * @param rule
 * @param value
 * @param callback
 */
export const checkWhole = (rule: any, value: any, callback: any) => {
  if (value === null || value === "" || value === undefined) {
    return callback(new Error("请输入数字"))
  }
  if (value == 0) {
    return callback()
  }
  if (!/^-?[1-9]\d*$/.test(value)) {
    return callback(new Error('请输入整数'))
  }
  if (parseInt(value) < 0) {
    return callback(new Error('请输入正数'))
  }
  callback()
}

/**
 * 判断手机号
 * @param rule
 * @param value
 * @param callback
 * @returns
 */
export const checkPhone = (rule: any, value: any, callback: any) => {
  if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)) {
    return callback(new Error('请输入有效的手机号'))
  }
  callback()
}


/**
 * 判断邮箱
 * @param rule
 * @param value
 * @param callback
 * @returns
 */
export const checkEmail = (rule: any, value: any, callback: any) => {
  if (!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
    return callback(new Error('请输入有效的电子邮箱'))
  }
  callback()
}

/**
 * 判断字母
 * @param rule
 * @param value
 * @param callback
 * @returns
 */
export const checkLetter = (rule: any, value: any, callback: any) => {
  if (!/^[A-Z]{1,2}$/.test(value)) {
    return callback(new Error("分类字母需为大写且不超过两位"));
  }
  callback()
}
