import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {ISystemRole,ISystemRoleQuery} from "@/apis/systemManage/role/types";
import {ISystemLog, ISystemLogQuery} from "@/apis/systemManage/log/types";

/**
 * 角色下拉
 * @returns
 */
export const systemRoleDownListApi = () => get("/card/systemRole/pullDownList")
/**
 * 角色分页查询
 * @param query 查询参数
 * @returns
 */
export const systemRoleQueryApi = (query: ISystemRoleQuery) => get<IPageRes<ISystemRole[]>>("/card/systemRole/query", query)
/**
 * 创建角色
 * @param data 角色参数
 * @returns
 */
export const systemRoleCreateApi = (data: ISystemRole) =>  postJ("/card/systemRole/create", data)
/**
 * 修改角色
 * @param data 参数
 * @returns
 */
export const systemRoleModifyApi = (data: ISystemRole) =>  postJ("/card/systemRole/modify", data)
/**
 * 删除角色
 * @param id 角色id
 * @returns
 */
export const systemRoleRemoveApi = (id: string) => get("/card/systemRole/remove/" + id)
/**
 * 角色详情
 * @param id 角色id
 * @returns
 */
export const systemRoleDetailApi = (id: string) => get("/card/systemRole/detail/" + id);

/**
 * 日志分页查询
 * @param query 查询参数
 * @returns
 */
export const systemLogApi = (query: ISystemLogQuery) => get<IPageRes<ISystemLog[]>>("/system/system/log/query", query)
